'use client';
import Image from 'next/image';
import React, { useCallback, useState } from 'react';

import { cn } from '@/lib/utils';

import { Icons } from './icons';

const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`;

type PropType = {
  imgSrc: string;
  alt: string;
  inView: boolean;
  index?: number;
  fill?: boolean;
  width?: number;
  height?: number;
  className?: string;
  sizes?: string;
  priority?: boolean;
};

export const LazyLoadImage: React.FC<PropType> = (props) => {
  const { imgSrc, inView } = props;
  const [hasLoaded, setHasLoaded] = useState(false);

  const setLoaded = useCallback(() => {
    if (inView) setHasLoaded(true);
  }, [inView, setHasLoaded]);

  return (
    <>
      <div className="h-full w-full flex-shrink-0 flex-grow">
        {!hasLoaded && (
          <div className="flex h-full items-center justify-center">
            <Icons.spinner
              className="h-10 w-10 animate-spin text-foreground"
              role="status"
              aria-label="Loading"
            />
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <Image
          src={inView ? imgSrc : PLACEHOLDER_SRC}
          alt={`Car Image ${props.alt}`}
          fill={props.fill}
          onLoad={setLoaded}
          sizes={props.sizes ? props.sizes : '100vw'}
          width={props.width}
          height={props.height}
          priority={props.priority}
          className={cn(
            `block h-auto w-full object-cover opacity-0 transition-opacity duration-200 ease-in-out ${hasLoaded ? 'opacity-100' : 'opacity-0'}`,
            props.className,
          )}
        />
      </div>
    </>
  );
};
